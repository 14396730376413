<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { useLocale, availableLocales } from '~/composables/locale'

const props = defineProps({
  type: {
    type: String,
    default: 'dropdown-right-top',
  },
})

const { currentLocale, switchLocale, t } = useLocale()
const currentStyle = toRef(props, 'type')
</script>

<template>
  <div class="flex items-center">
    <!-- Dropdown version -->
    <Listbox
      v-if="type === 'dropdown-right-top'"
      v-model="currentLocale.code"
      as="div"
      class="relative flex items-center"
      @update:model-value="switchLocale"
    >
      <ListboxLabel class="sr-only">{{
        t('components.language_switcher.change_language')
      }}</ListboxLabel>

      <ListboxButton
        type="button"
        :title="t('components.language_switcher.change_language')"
        class="transition-colors duration-300"
      >
        <span class="justify-center items-center flex">
          <div
            class="py-2 px-2 flex items-center cursor-pointer ease-in-out duration-150 text-black hover:bg-[#45994526] rounded-btn"
          >
            <span class="text-sm mr-2">{{ currentLocale.flag }}</span>
            <span class="text-base uppercase font-black">{{
              currentLocale.iso
            }}</span>
            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              class="ml-3 text-green"
            />
          </div>
        </span>
      </ListboxButton>

      <ListboxOptions
        class="p-1 absolute z-50 top-full right-0 outline-none bg-white rounded-lg ring-1 ring-gray-900/10 shadow-lg overflow-hidden w-38 py-1 text-sm text-gray-700 font-semibold dark:bg-gray-800 dark:ring-0 dark:highlight-white/5 dark:text-gray-300"
      >
        <ListboxOption
          v-for="lang in availableLocales"
          :key="lang.code"
          v-slot="{ active, selected }"
          :value="lang.code"
        >
          <div
            :class="[
              'py-2 px-2 flex items-center cursor-pointer',
              selected
                ? 'text-green bg-[#45994526] rounded-md'
                : 'ease-in-out duration-150 hover:bg-soft-gray rounded-md',
            ]"
          >
            <span class="text-sm mr-2">{{ lang.flag }}</span>
            <span class="flex-1 truncate">
              {{ lang.name }}
              <span class="text-xs uppercase">({{ lang.iso }})</span>
            </span>
          </div>
        </ListboxOption>
      </ListboxOptions>
    </Listbox>

    <!-- Select box version -->
    <select
      v-else
      :value="currentLocale.code"
      class="w-full px-2 pr-3 py-2 outline-none rounded-btn border bg-transparent text-black"
      @change="(e) => switchLocale((e.target as HTMLSelectElement).value)"
    >
      <option
        v-for="lang in availableLocales"
        :key="lang.code"
        :value="lang.code"
      >
        {{ lang.flag }} {{ lang.name }} ({{ lang.iso }})
      </option>
    </select>
  </div>
</template>
