import { useI18n } from '#imports'

export type LocaleCode = 'si'

export interface Locale {
  code: LocaleCode
  iso: string
  name: string
  flag: string
}

export const availableLocales: Locale[] = [
  {
    code: 'si',
    iso: 'si',
    name: 'Slovenščina',
    flag: '🇸🇮',
  },
]

// This combines both the old useLang and new useLocale functionality
export function useLocale() {
  const { locale, t } = useI18n()
  const switchLocalePath = useSwitchLocalePath()

  const currentLocale = computed(() => {
    return (
      availableLocales.find((l) => l.code === locale.value) ||
      availableLocales[0]
    )
  })

  const switchLocale = (localeCode: string) => {
    // Ensure only valid locale codes are used
    const validLocaleCode = availableLocales.some((l) => l.code === localeCode)
      ? (localeCode as LocaleCode)
      : 'si'

    locale.value = validLocaleCode
  }

  return {
    currentLocale,
    availableLocales,
    switchLocalePath,
    t,
    switchLocale,
    locale,
  }
}

// For backwards compatibility with existing components
export function useLang() {
  const { t } = useLocale()
  return { t }
}
